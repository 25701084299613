import React, { useEffect, useState } from "react";
import { Flex, Progress, Text, SimpleGrid, Box } from "@chakra-ui/react";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import search from "../../design-system/icons/Search/search-normal.svg";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { Paginator } from "../../components/Paginator/Paginator";
import useQuery from "../../hooks/useQuery";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as sales } from "./ducks/sales.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import SalesItem from "../../components/SalesItem/SalesItem";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import SalesItemLoader from "../../components/SalesItem/SalesItemLoader";

const SalesPage = ({
  listSales,
  approveSales,
  rejectSales,
  result,
  ...props
}) => {
  const query = useQuery();
  const tenant = query.get("tenant") ?? "vista";
  const state = query.get("state");
  const d = query.get("d");

  const page = query.get("page");
  const perPage = query.get("per_page");
  const from = query.get("from");
  const to = query.get("to");

  const history = useHistory();
  const { pathname } = useLocation();

  const mainState = useSelector((state) => state);

  const filteredSales = mainState.sales.sales;
  const [remitNumber, setRemitNumber] = useState("");
  const [sellerComments, setSellerComments] = useState("");

  useEffect(() => {
    listSales({
      state,
      page,
      perPage,
      from,
      to,
    });
  }, [state, page, perPage, from, to, listSales]);

  const handleApproveOrder = (item, itemsState) => {
    approveSales({
      item,
      items: itemsState,
      dispatch_note_number: remitNumber,
    });
  };

  const handleRejectOrder = (item, itemsState) => {
    rejectSales({ item, items: itemsState });
  };

  const handlePagination = () => {
    listSales({
      state,
      page,
      perPage,
      from,
      to,
    });
  };

  function handleFillResult(state) {
    result(state);
  }

  const arr = [...new Array(mainState.sales.salesPagination.totalPages)];

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });

  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment()
        .add(-24, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment()
        .add(-1, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment()
        .add(-3, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment()
        .add(-6, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment()
        .add(-12, "M")
        .format("YYYY-MM-DD");
    }
    item?.date &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}`
      );
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        /*{...props}*/
      >
        <ApproveOrdersBreadcrumb />
        <AlertBekeu my={"32px"}>
          Recordá que una vez recibida tu venta debes enviar el pedido.
        </AlertBekeu>
        <H5>Mis ventas</H5>
        <Flex justifyContent={"flex-end"}>
          <SimpleGrid
            mt={"32px"}
            minChildWidth="200px"
            spacing={4}
            w={"100%"}
            mr={"35px"}
          >
            {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
            {/* <InputBekeu
          disabled
          placeholder={"Buscar"}
          bg={"white"}
          lefticon={<BekeuIcon src={search} mr={4} />}
        /> */}
           <Box>
              <MenuBekeu
                  height={"56px"}
                  ml={"30px"}
                  items={[
                    { name: "Bekeu Pro", tenant: "tenant" },
                    { name: "Pampa Energía", tenant: "pampa"},
                    { name: "Vista Energy", tenant: "vista"},
                  ]}
              >
                {!tenant || tenant === "tenant"
                ? "Bekeu Pro"
                : tenant === "pampa"
                  ? "Pampa Energía"
                  : tenant === "vista"
                    ? "Vista Energy"
                    : "Bekeu Pro"}
              </MenuBekeu>
            </Box>
            <Box>
              <MenuBekeu
                height={"56px"}
                ml={"30px"}
                items={[
                  { name: "Pendiente", state: "2" },
                  { name: "Entrega en proceso", state: "9" },
                  { name: "Canceladas", state: "5" },
                  { name: "Reclamadas", state: "8" },
                  { name: "Confirmado por cliente", state: "7" },
                  { name: "Finalizadas", state: "10" },
                ]}
              >
                {state && state === "1"
                  ? "Pendiente"
                  : state === "2"
                  ? "Pendiente"
                  : state === "3"
                  ? "Rechazado"
                  : state === "4"
                  ? "Enviada"
                  : state === "5"
                  ? "Cancelada"
                  : state === "6"
                  ? "Entregado"
                  : state === "7"
                  ? "Confirmado por cliente"
                  : state === "8" && "Reclamado"
                  ? "Entrega en proceso"
                  : state === "9" && "Entrega en proceso"}
              </MenuBekeu>
            </Box>
            <Box>
              <MenuBekeu
                height={"56px"}
                leftIcon
                setMoment={setMoment}
                state={state}
                ml={"34px"}
                items={[
                  {
                    name: "Todas",
                    date: "Todas",
                    to: formattedDate,
                    d: 0,
                  },
                  {
                    name: "Este mes",
                    date: "Este mes",
                    to: formattedDate,
                    d: 1,
                  },
                  {
                    name: "Últimos 3 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 3,
                  },
                  {
                    name: "Últimos 6 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 6,
                  },
                  {
                    name: "Últimos 12 meses",
                    date: "Últimos 3 meses",
                    to: formattedDate,
                    d: 12,
                  },
                ]}
              >
                {d
                  ? d === "0"
                    ? "Todas"
                    : d === "1"
                    ? "Este mes"
                    : d === "3"
                    ? "Últimos 3 meses"
                    : d === "6"
                    ? "Últimos 6 meses"
                    : d === "12" && "Últimos 12 meses"
                  : "Todas"}
              </MenuBekeu>
            </Box>
          </SimpleGrid>
        </Flex>
        {!mainState.sales.loading ? (
          <>
            {filteredSales && filteredSales.length > 0 ? (
              filteredSales.map((item, id, itemArr) => {
                return (
                  <SalesItem
                    mt={"32px"}
                    type={item.state}
                    key={id}
                    item={item}
                    state={item.state}
                    remitNumber={remitNumber}
                    handleApproveOrder={handleApproveOrder}
                    setRemitNumber={setRemitNumber}
                    handleRejectOrder={handleRejectOrder}
                    setSellerComments={setSellerComments}
                    sellerComments={sellerComments}
                    handlePagination={handlePagination}
                    itemArr={itemArr}
                    handleFillResult={handleFillResult}
                  />
                );
              })
            ) : (
              <Text mt={6} className="animate__animated animate__fadeIn">
                No se encontraron ventas{" "}
                {state === "1"
                  ? "pendientes"
                  : state === "2"
                  ? "pendientes"
                  : state === "3"
                  ? "rechazadas"
                  : state === "4"
                  ? "enviadas"
                  : state === "5"
                  ? "canceladas"
                  : state === "6"
                  ? "entregados"
                  : state === "7"
                  ? "confirmados por parte del cliente"
                  : state === "8" && "reclamadas"}
              </Text>
            )}
            {!mainState.sales.loading &&
              mainState.sales &&
              mainState.sales.salesPagination.totalPages > 1 && (
                <Paginator
                  totalItems={mainState.sales.salesPagination.totalPages}
                  itemsArray={arr}
                  perPage={5}
                  mt={"24px"}
                />
              )}
          </>
        ) : (
          [1, 2].map((item, id) => (
            <SalesItemLoader item={item} key={id} mt={"32px"} />
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...sales, ...product.actions })(SalesPage)
);
